/* global $ */

function resizeDropdown (dropdown) {
  if (!dropdown || !dropdown.length) {
    return
  }
  var left = parseFloat(dropdown.css('left'), 10)
  var offset = $(document).width() + left - (dropdown.offset().left + dropdown.width())
  if (offset < 0) {
    dropdown.css('left', offset + 'px')
  } else {
    dropdown.css('left', '')
  }
}

$(function () {
  $('a.dropdown-toggle').click(function () {
    var button = $(this)
    setTimeout(function () {
      if (button.attr('aria-expanded') === 'true') {
        resizeDropdown(button.next())
      }
    }, 5)
  })
  $(window).resize(function () {
    resizeDropdown($('a.dropdown-toggle[aria-expanded=true]').next())
  })
})
